
.drop-profile .img-user{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.cart-profile .bacT-derop {

    width: 100%;
}
.cart-profile ul {
    list-style: none !important;
}
.cart-profile .img-users .imgUser
{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-top: -42px;
    /* border: 2px solid #23bae2; */
    font-size: 60px;
    display: inline-block;
    background: #fff;
    line-height: 1;
    color: #1394b5;
    object-fit: contain;

}

/* .cart-profile{
    width: 249px;
   
} */
.top-profile-div{
    background-image: url("../../asset/images/profile-top.webp");
    background-size: cover;
    height: 270px;
}
.profile-section{
    margin-top: -170px
}
.cart-profil-sec
{
    box-shadow: 0px 0px 5px #2b2f3826;
    background: #fff;
}
.charting{
    min-height: 410px;
    background: #fff;
    box-shadow: 0px 0px 10px #ddd;
}

.div-charge {
    background:#1FBBE3;
    /* background-size: cover; */
    color: #fff;
margin-bottom: 10px;
min-height: 100px;

}
.div-point{
    background: #5F4898;
    /* background-size: cover; */
    color: #fff;
    margin-bottom: 10px;
    min-height: 100px;
}
.div-point button{
    color:#5F4898;
    background-color: #fff;
    border-radius: 40px;
}
.div-charge button{
    color:#1FBBE3;
    background-color: #fff;
    border-radius: 40px;
}
.form-profile input.form-control {
    padding: 20px 16px 20px 0px;
    border: none;
}

.form-profile 
{
    box-shadow: 0px 0px 20px #eee;
    padding: 20px;
    margin-top: 23px;
    margin-bottom: 86px;
}

@media screen and (max-width:480px) {
    .dropdown-menu.show {
      
        left: -44px;
    }
}
.add-img
{
    background: #fff;
    margin: 13px;
    color: #0dcaf0;
}
.add-img:hover{
    background: #fff !important;
    margin: 13px  !important;
    color: #0dcaf0  !important;
    transform: scale3d(1.1, 1.1, 1.1)
}
.ltr
{
    direction: ltr;
}
.edit_btn{
    cursor: pointer
}
/* g.apexcharts-xaxis-texts-g {
    transform: translate(10px, 34px) ;
    
} */
 