
.placeholder-phone
{
    position: absolute;
    right: 82px;
    top: 5px;
    color: #827a7a;
}
.singup-form form{
    color: #1fbbe3;
}
input.form-control {
    padding: 5px 32px 5px 1px !important;
    color: #1fbbe3 !important;
}