.css-ascpo7-MuiStepLabel-root {
    flex-direction: column !important;
}
.css-1hv8oq8-MuiStepLabel-label {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    margin-top: 17px;
    margin-bottom: -28px;
}
.step-shipping-container {
    width: 100%;
  }
  
  .step-shipping-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .step-shipping-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .step-shipping-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #757575;
    font-size: 18px;
  }
  
  .step-shipping-label {
    margin-top: 8px;
    text-align: center;
  }
  
  .step-shipping-item.active .step-shipping-icon {
    background-color: #1976d2;
    color: #fff;
  }
  
  .step-shipping-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
  
  .step-shipping-step {
    margin: 0;
  }
  
  .step-shipping-buttons {
    display: flex;
  }
  
  .step-shipping-buttons button {
    margin-left: 8px;
  }
  