.more-prodects
{
    background:#FAFAFA;
    min-height: 34vh;
}
.more-prodects h5.card-title {
    /* color: #2B2F38; */
    min-height: 45px;
    overflow: hidden;
}
div#freeprodect {
    --bs-modal-box-shadow: none;
}   

/* .add-card {
    background: #1fbbe3;
    color: #fff;
    font-size: 15px;
    padding: 11px;
    width: 75% !important;
} */