

.slider-prodects .old-price {
    text-decoration: line-through;
    color: #80848a;
}
span.new-price {
    color: #1FBBE3;
}
p.card-text {
    color: #80848A;
}
/* h5.card-title {
    color: #2B2F38;
} */
.icon-card
{
    color: #80848A;
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
}
.icon-card span
{
    background: #eee;
    display: flex;
    width: 47px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    height: 47px;
    margin: 20px auto;
}
.icon-card span.active{
    background: #1FBBE3;
    color: #fff;

}
.reting-div
{
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 20px;
    color: #000;
    background: #fff;
    padding: 1px 5px;
    color: #6E6E6E;
}
.reting-div .icon{
    color:#FFC300
}
.sale-div
{
    position: absolute;
    color: #000;
    width: 200px;
    height: 200px;
    left: -43px;
    top: 17px;
}
.sale-div div{
    background: #5F4898;
    color: #fff;
    transform: rotate(318deg);
    text-align: center;
    padding: 8px;
    font-size: 20px;
}
.card{
    overflow: hidden;
}
.card-img, .card-img-top {
  
height: 250px;
    object-fit: contain;
}
.imag-card{
    height: 250px;
}


  /* .card {
        border: none;
        height: 487px;
    } */
