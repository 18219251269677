.scend-color{
    color:#5f4898;
}
.scend-color button{
    color: #5f4898 !important;
    background: #eeeded;
    border: none;
}
.scend-color button:hover{
    color: #5f4898 !important;
    background: #eeeded !important;
    border: none;
}
.old-price{
    text-decoration: line-through;
    color: #acacac;
}

.star-active{
    color:#FFC300
}
.remove-btn{
    background: rgb(237, 237, 237);
    font-size: 20px;
    padding: 4px 5px;
    line-height: 1;

}
.add-btn{
     font-size: 20px;
    padding: 4px 5px;
    line-height: 1;
}

.add-btn.active,.remove-btn.active{
    background: #5f4898;
    color: #fff !important;
}
.add-card
{
    background: #1fbbe3;
    color: #fff;
    font-size: 18px;
    padding: 10px;
}
.detailes-card
{
    line-height: 1.5;
}
.detailes-card,.detailes-card .btn-heart
{
    color: #707070;
}
.addQuntDiv
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
}