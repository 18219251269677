.sigIn-section {
    background-image: url("../../asset/images/Group\ 4025392.webp");
    background-size: cover;
    padding: 20px;
    min-height: 66vh;
}

.sigIn-section .div-content {
    background: #ffff;
    padding: 20px;
}

input.form-control {
    padding: 0px 56px 0px 0px;
}

.react-tel-input .selected-flag .arrow {
    display: none;
}

.react-tel-input .form-control {
    background: transparent !important; 
    color: #29BEE4;
    padding: 17px 30px;
    font-size: 15px;
    /* margin-top: 19px !important; */
    height: 39px;
    width: 100% !important;

}

.selected-flag::before {
    content: "|";
    position: absolute;
    left: -32px;
    top: 5px;
    color: #29BEE4;
}
.icon-pass
{
    position: absolute;
    right: 9px;
    top: 8%;
    font-size: 20px;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    background-color: transparent !important;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    border-left: none !important
}
.btn-login
{
    background: #1fbbe3;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    margin: 13px auto;
}
.forgect-pass{
    float: left;
    color: #1fbbe3;
    padding-top: 13px;
}
.linkNewAcount, .sigIn-section h2{
    color: #1fbbe3;
}
.color-text-singin{
   color: #80848A;
}
.link-none{
  
        color: inherit;
    
}
.btn-login:hover{
    background: #1fbbe3 !important;
    color: #fff !important;
}
.sigin-by-media img{
    width: 50px;
}
input.form-control.tel
{
    padding-right: 3px !important;
}