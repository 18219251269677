 .shoping-car .arrow-right
{
    margin-right: -55px;
    background: #fff;
    padding: 0px 9px;
    font-size: 28px;
    border-radius: 50%;
}

.shoping-car  .div-reting
{
    background: #fff !important;
    border-radius: 20px;
    padding: 4px;
    top: 3px;
    line-height: 1;
    right: 2px;
}
.shoping-car .img-shoping
{
    /* width: 110px; */
    /* margin-left: 8px;
    height: 200px; */
    object-fit: contain;

}
.shoping-car p ,.shoping-car h6{
    line-height: 1.5;
}
 
.add-btn{
background: #5F4898;
color: #fff;
}
 div#offcanvasScrolling {
    width: 55% !important;
    padding: 10px 20px !important;
}
@media screen and (max-width: 484px) {
    div#offcanvasScrolling {
        width: 90% !important;
        padding: 5px
    }
}
.close-icon 
{
    top: -2px;
    right: 0px;
    background: #1FBBE3;
    color: #fff;
    border-radius: 50%;
    font-size: 26px;
    line-height: 0;
    width: 26px;
    padding: 0;
    height: 25px;
 
}
.offcanvas-body{
    overflow: unset !important;
    padding: 2px;
}
.offcanvas-body .total-price{
    background-color: #FAFAFA;
    padding: 10px;
}
.content-carts
{
    height: 55vh;
    overflow-x: hidden;
    padding: 14px 14px 0px 0px;
}

.pop-cart {
    background: #fff;
    padding: 16px 3px 4px!important;
    border-radius: 12px;
    margin: 16%;
    transition: .5s;
    text-align: center;
    color: #43336c;
    width: 68%;
    margin-top: 30%;

  }
  @media screen and (min-width:1024px) {
    
.pop-cart {
 
    width: 50%;
  
    margin: 25%;
  }
  }
.pop-cart-div
{
    position: absolute;
    z-index: 10000000000;
    background: #0000004a;
    box-shadow: 0px 0px 10px #ddd;
    padding: 10px 10px 0px 10px;
    border-radius: 12px;
    top: 10%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .5s;
} 
@media screen and (max-width:800px) {
    .pop-cart {
        
        margin: 0px;
        transition: .5s;
        width: 90%;
        margin-top: 50%;
 
    }
}







