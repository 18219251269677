
.completBuy .div-num-order{
    background-image: url("../../asset/images/backSuccess.webp");
    background-size: cover;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
}
.completBuy .div-num-order .icon
{
    padding: 13px;
    display: inline-block;
    background: #fff;
    color: #1fbbe3;
    font-size: 27px;
    border-radius: 50%;
    margin-bottom: 18px;
}
.completBuy  .data-orders{
    justify-content: space-between;
    margin-right: 10px;
}
.completBuy .shar-prodects img
{
    width: 110px;
};
button.goog-icon {
    color: #D44638;
    border: 1px solid;
    padding: 0px 10px;
    margin: 3px;
    background: #fff ;
}
button.face-icon {
    color:#64519E ;
    border: 1px solid;
    padding: 0px 10px;
    margin: 3px;
    background: #fff ;
}
button.twi-icon {
    color: #00ACEE;
    border: 1px solid;
    padding: 0px 10px;
    margin: 3px;
    background: #fff ;
}
.completBuy  .shar-prodects {
    box-shadow: 0px 0px 7px #ddd;
    padding: 14px 8px ;
    min-height: 219px;

}
.copy-link-order{
    background: #FAFAFA;
    padding:  5px;
    color: #2B2F38;
    padding: 5px 14px;
}

.delivery-confirmation .cheked {
    background: #FAFAFA;
    padding: 10px 5px;
    color: #2B2F38;
}

.delivery-confirmation .form-rating {
    padding: 20px;
    background: rgb(95,72,152);
    background: linear-gradient(336deg, rgba(95,72,152,1) 20%, rgba(31,187,227,1) 100%);
    color: #fff;
}
.delivery-confirmation .undefined.react-stars {
    display: flex;
}
.steps .step {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    text-align: center
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 3px;
    margin-top: -1px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1;
    right: 27% !important;
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 65px;
    height: 65px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 28px;
    line-height: 60px;
    z-index: 5;
}

.steps .step .step-title {
    margin-top: 16px;
    margin-bottom: 0;

    font-size: 14px;
    font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
    display: none
}

.steps .step:last-child .step-icon-wrap::after {
    display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: #0da9ef
}

.steps .step.completed .step-icon {
    border-color: #0da9ef;
    background-color: #0da9ef;
    color: #fff
}

@media (max-width: 576px) {
    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: block;
        position: absolute;
        top: 50%;
        width: 2px;
        height: 72%;
        margin-top: -1px;
        background-color: #e1e7ec;
        content: '';
        z-index: 1;
        right: 50% !important;
    }
}

@media (max-width: 768px) {
    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

.bg-faded, .bg-secondary {
    background-color: #f5f5f5 !important;
}
.cardstep{
    border:none
}
.notpay
{
    text-align: center;
    height: 73vh;
    margin-top: 12%;
}