
.main-color{
    color:#1FBBE3
}
.add-card:hover{
        background: #22c4ed !important;
        color: #fff !important;
}
.alert-shown{
    
     position: fixed;
     right: 49px;
    top: 84px;
    display: block;
    opacity: 1;
    transition: all 250ms linear;
    z-index: 17;
     box-shadow: 0px 0px 5px #dddd;
    background: #fff;
    font-size: 16px;
    border-radius: 12px;

  }
  .alert-hidden{
   
    display:none ;
  }
.card {
    border: none;
    min-height: 446px;
    margin: 0px auto 20px;
}
.card-img, .card-img-top {
    height: 230px;
}

.add-btn:hover ,.icon-card .btn:hover, .remove-btn:hover{
    color: #fff !important;
    background: #1fbbe3 !important;
}
.spinner {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(237, 235, 235); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1FBBE3; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1FBBE3; 
      width: 15px;
  }

  .spinnerMAin{color: #1fbbe3;}
  
  .input-phone-dir{
    direction: ltr;
  }
  .modal-dialog {
    margin-top: 18%;
}
/* Chrome, Safari, Edge, Opera */
input.input-quntaty::-webkit-outer-spin-button,
input.input-quntaty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spinnerMAin{
  color:#1fbbe3;
  text-align: center;
  margin-top: 20%;
  margin-bottom: 20%;

  font-size: 30px;
  
}

.btn_pop_massge ,.btn_pop_massge:hover{
  background: #1fbbe3;
  color: #fff !important;
  font-size: 18px;
  padding: 10px;
  background: #43336c !important;
  width: 50% ;
  margin-bottom: 16px;
} 

@media screen and (max-width:800px) {
  .btn_pop_massge  {
      
      width: 70% ;

  } 
}
.text_second_color{
  color: #43336c;

}
.pop_divce_phone {
  position: fixed;
  z-index: 1000;
  background: #fff;
  right: 20px;
  left: 20px;
  text-align: center;
  top: 10%;
  border-radius: 15px;
  box-shadow: 0px 0px 2px #39393954;
  padding: 10px;
  max-width: 400px;
  margin: auto;
}