.div-top-shoping {
background-image: url("../../asset/images/backTopChoping.webp");
background-position-y: bottom;
color:#fff;
font: 20px;
}
.div-top-shoping button{
    border: 1px solid #fff;
    color:#fff;
    margin: auto 20px;
}
.div-top-shoping button:hover{
    border: 1px solid rgb(238, 238, 238) !important;
    color:#fff
}

.btn-delete{
    color:#EB3D34;
    box-shadow: 0px 0px 5px #ddd;
}
.shopingCarPage table img{
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-left: 8px;
}
.shopingCarPage .name-prodect
{
    font-size: 15px;
    text-align: center;
    margin-right: 4px;
    width: 250px;
    margin-left: 20px;
}
.shopingCarPage  .delete-icon{
    background: #CACED5;
    border-radius: 50%;
    text-align: center;
    font-size: 21px;
    color: #fff;
    line-height: 0;
    height: 29px;
    width: 29px;
    padding-right: 4px;
}
.shopingCarPage  .add-btn {
    padding: 1px 3px;
}
.shopingCarPage  .remove-btn {
    padding: 1px 3px;
}
.shopingCarPage table tr ,.shopingCarPage table h5{
    color:#7e7e7e
}
.shopingCarPage table  td {
    min-width: 100px;
}
.shopingCarPage table tr.header-tabel
{
    background: #00000008
}
.shopingCarPage ::-webkit-scrollbar-thumb {
    background: #1fbbe312;
    border-radius: 11px;
    height: 1px !important;
    border-width: 1px !important;
}
.shopingCarPage table
{
    border-color: #e4e4e4;
}
.date-input::placeholder{
    font-size: 1px;
}
.totale-prodects {
    background: #f2f2f2;
    padding: 10px;
    margin: 18px auto 66px;
}
.cabon-btn
{
    margin-right: 13px;
    padding: 8px 29px;
    background: #5F4898;
}
.table>:not(caption)>*>* {
    padding: 0.5rem 0rem !important;
   
}
.addQunddiv
{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
}
.errovalid{
    border: 1px solid #f00;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
input[type="date"]{
    position: relative
}