
@font-face {
    font-family: 'NotoKufiArabic-Ligh';
    src: local('NotoKufiArabic-Light'), url(./fonts/Arabic/NotoKufiArabic-Light.ttf) format('truetype');
    font-display: swap;

  }
  @font-face {
    font-family: 'NotoKufiArabic-Bold';
    src: local('NotoKufiArabic-Bold'), url(./fonts/Arabic/NotoKufiArabic-Bold.ttf) format('truetype');
    font-display: swap;

  }
  @font-face {
    font-family: 'NotoKufiArabic-ExtraLight';
    src: local('NotoKufiArabic-ExtraLight'), url(./fonts/Arabic/NotoKufiArabic-ExtraLight.ttf) format('truetype');
    font-display: swap;

  }

  @font-face {
    font-family: 'NotoKufiArabic-ExtraBold';
    src: local('NotoKufiArabic-Medium'), url(./fonts/Arabic/NotoKufiArabic-Medium.ttf) format('truetype');
    font-display: swap;

  }
  @font-face {
    font-family: 'NotoKufiArabic-Regular';
    src: local('NotoKufiArabic-Regular'), url(./fonts/Arabic/NotoKufiArabic-Regular.ttf) format('truetype');
    font-display: swap;

  }
body{
    direction:rtl;
    /* font-family: 'NotoKufiArabic-ExtraBold'; */
}
p,a{
    font-family: 'NotoKufiArabic-Regular';

}
h1,h2,h3,h4,h5,h6{
    font-family: 'NotoKufiArabic-Bold';

}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: #fff;
  pointer-events: none;
  background-color: #06627a;
  border-color: var(--bs-btn-disabled-border-color);
  opacity: .5;
}
.btn{
  transition: 1s;
}
.search_input_map{
  margin-top: -38px;
}