.DetailsSlider .thumbnails {
    display: flex;
    align-items: end;
    overflow: scroll;
    flex-direction: column;
}
.DetailsSlider .box {
    display: flex;
    text-align: center;
    max-width: 850px;
    max-height: 300px;
    /* margin: 40px auto; */
    flex-wrap: wrap;
    flex-direction: column;
    direction: ltr;
}
.carousel-image {

    background: #fff !important;
}
.DetailsSlider .fade:not(.show) {
    opacity: 1;
}
.DetailsSlider  .sale-div {
    left: -43px;
    top: 27px;
    z-index: 25;
}
img.carousel-image{
    object-fit: contain;
}
.DetailsSlider  .reting-div {
  
    right: 120px;

    z-index: 20;
}
.DetailsSlider .carousel-item img {
    width: 201% !important;
    position: absolute;
    bottom: 0px;
    border: 2px solid #0e0e0e2e;
    left: 0%;
    z-index: 10;
}
.DetailsSlider .carousel-container {
    max-width: 100% !important;
    max-height: 300px !important;
}
.DetailsSlider .carousel-container a.prev,.carousel-container a.next  {
    display: none;
}

.DetailsSlider .carousel-container .thumbnail {
    margin: 2px 3px;
}
.DetailsSlider .active-thumbnail {
    border: #1fbbe3 solid 3px;
}
.DetailsSlider .thumbnails img {
    margin: 10px;
    width: 100px;
    height: 100px;
    object-fit: contain;

}
.DetailsSlider .thumbnails  {
    margin-top: -8px;
}
.DetailsSlider .fade:not(.show) {
    background: #fff;
}
img.carousel-image {
    object-fit: contain !important
}