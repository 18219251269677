.pageFollowOrder .link-follow{
    color: #5F4898;
    text-decoration: unset;
}
.list-prodects .img-shoping{
    width: 103px;
    margin-left: 4px;

}
.list-prodects .div-reting {
    background: #fff;
    border-radius: 25px;
    padding: 0px 3px;
}