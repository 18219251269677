 

.form-secton .form-div {
    background: #fff;
    padding: 15px;
    box-shadow: 0px 0px 13px #ddd;
    line-height: 2.5;
    padding-left: 50px;
}
.form-secton .form-div button
{
    background: #5F4898;
    border: none;
}
.form-secton .form-div button:hover{
    background: #503594;
    border: none;
}
.form-secton {
    background: #fff;
    padding-top: 5px;
    padding-bottom: 50px;
}

.form-secton img {
    transform: translate(40px, -54px);
}

.form-secton .img-form {
    background: #1FBBE3;
}
.form-secton input.form-control {
    padding: 6px 12px 6px 0px;
}
.icons-left {
    position: absolute;
    left: 0px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    background: #1fbbe3;
    color: #fff;
    border-radius: 57px;
    position: fixed;
    left: 0px;
    top: 34%; 
    z-index: 20;
}
.icons-left a{
    color: #fff;
}
.btn-toTop{
    background: #5f4898 ;
    font-size: 25px;
    line-height: 1;
    padding: 2px 4px;
    border-radius: 50%;
    position: fixed;
    bottom: 6%;
    left: 1%;
    color: #fff;
    z-index: 20;
    transition: .1s;
}
.btn-toTop:hover{
    background: #60489f !important ;
    color: #fff !important;
    transform: translateY(-10px)
    
    
}
.slick-prev:before, .slick-next:before {
    font-family: 'FontAwesome' !important;
    font-size: 26px;
    line-height: 1.1;
    opacity: .75;
    border: 2px solid;
    border-radius: 50%;
    padding: 5px;
}
