.more-prodects
{
    background:#FAFAFA
}
.Reting-section img{
    width: 100px;
    margin-left: 12px;
    border-radius: 50%;
    height: 100px;
    border: 3px solid #e9e9e9;
    box-shadow: 0px 0px 10px #a2a2a2;
}
.Reting-section .date-post {
color:#80848A
}
.Reting-section 
{
    color:#5a5c5f
}
.Reting-section .text-rating
{
    background: #f6f6f6;
    border-radius: 6px;
}
.form-rating {
    padding: 20px;
    background: rgb(95,72,152);
    background: linear-gradient(336deg, rgba(95,72,152,1) 20%, rgba(31,187,227,1) 100%);
    color: #fff;
}
.undefined.react-stars span{
    font-size: 40px !important;
}
.form-rating input,.form-rating textarea
{
    background: #ffffff24;
    color: #fff !important;
    border: none;
    opacity: 1;
}
.form-rating input:focus ,.form-rating textarea:focus{
    color:rgb(34, 34, 34);
    background: #e4e2e224;
}

.form-rating input::placeholder,.form-rating textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 1; /* Firefox */
  }
  .form-rating .btn-send-rating
  {
    background: #6F5BA2;
    color: #fff;
    border-radius: unset;
    padding: 8px 25px;
  }