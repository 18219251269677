
.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
    width: 100%;
    max-height: 650px;
    text-align: center;
    background: #ddd;
}

.carousel-fade .carousel-item{
    background: transparent !important;
}
.carousel-item {
    /* height: 600px !important; */
    position: relative;
}


.carousel-item  .img-static
{
    width: 40% !important;
    position: absolute;
    bottom: -7px;
    left: 6%;
    z-index: 10;
}
.carousel-item  img
{
    max-height: 600px;
    margin-bottom: -11px
}
.carousel-caption {
    position: absolute;
    right: 11%;
    color: #fff;
    text-align:initial;
    background: #0000001a;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    /* border-radius: 20px; */
    min-width: 79%;
    bottom: 19%;
    line-height: 2;
}
.carousel-indicators [data-bs-target] {
  
    width: 10px;
    height: 10px;
    border-radius: 50%;
 
}

.carousel-caption  h2
{
    font-size: 35px;
}
.carousel-caption .btn-call{
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    margin: 3px;
    padding: 9px 23px;
}
.carousel-caption .btn-order{
    background: transparent;
    color: #fff;
    border: none;
    background: #1FBBE3;
    margin: 3px;
    padding: 9px 23px;
}
.carousel-inner{
    background-image: url("../../../asset/images/Group\ 4025392.webp");
    background-size: cover;
}
.carouser-section .carousel-control-next-icon:after ,.carouser-section .carousel-control-prev-icon:after  {
 
    font-size: 30px;
    color: #fff !important;
    background: #dddddd73;
    border-radius: 50%;
    padding: 3px 13px;
    font-weight: 600;
     font-family: monospace !important;
 
}
.carouser-section .carousel-control-next-icon:after {
    content: "<";

}
.carouser-section .carousel-control-prev-icon:after{
    content: ">";

}
.carouser-section .carousel-indicators [data-bs-target]{
    opacity: 1;
    border: 2px solid #fff;
}
.carousel-indicators .active{
    background-color: #1FBBE3;
}
 
.carousel-control-next, .carousel-control-prev{
    text-decoration: none;
    opacity: 1;
}
.carousel-control-prev-icon,.carousel-control-next-icon{
    background: none;
}