
.icon-right{
    width: 100px;
}
.btn-more{
    background: #1FBBE3;
    color: #ffff;
}
.btn-more:hover{
    background: #18a0c2 !important;
    color: #ffff !important;

}
.about-section{
    background: #ffff;
}
.color-main{
    color:#1FBBE3
}
.mark-word{
    color: #5F4898;
}
.parent-img
{
    padding: 22px;
    border-radius: 50%;
    background: #f7f5f5;
    width: 100px;
  
    color: #fff;
    height: 100px;
    display: flex;
    margin-bottom: 20px;
}
.about-section p
{
    color:#80848A;
    line-height: 1.7;
}
.p-top{
    color:#1FBBE3 !important;

}
.p-top:before {
        content: " ";
        width: 30px;
        height: 1px;
        display: inline-block;
        background:#1FBBE3 ;
      
}
.color-main{
    color:#1FBBE3
}
span.span-icon {
    line-height: 1;
    position: relative;
    margin: 10px auto;
    display:inline-block
}

@media screen and (max-width:480px) {
    .icons-left {
       
        background: #1fbce38a;
    
    
    }
    .btn-car-icon 
{

    padding: 0;
}
}
.btn-car-icon 
{
    font-size: 33px;
    color: #fff;
    padding: 2px;
    
}