.form-check-input:checked {
    background-color: #1FBBE3;
    border-color: #1FBBE3;
}
.form-check-input:checked .form-check-label {
  color: #1FBBE3 !important;
    
}


.cheked {
    background: #FAFAFA;
    padding: 10px 5px;
    color: #2B2F38;
}
 .form-checked
{
    background: #fafafa;
    margin-top: 21px;
    padding: 19px 5px;
    line-height: 1.4;
    color: #80848A;
}


 .form-checked input.form-control , .form-checked select.form-control, .form-checked textarea.form-control {
    padding: 8px 11px 8px 8px;
    margin-top: 14px;
    font-size: 14px;
    background: #FAFAFA;
    border-color: #e3e3e3;
}
 .form-checked h5{
    color:#000
}

.section-checked .close-icon {

    display: flex;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    padding: 3px
}
label.form-check-label {
    margin-right: 34px;
}
.section-checked  .totale-prodects {
    background: #fafafa;
    padding: 12px;
 
}

.section-checked  .div-reting {
    background: #fff;
    border-radius: 15px;
    padding: 1px 3px;
}
.section-checked  .prodects-list {
    box-shadow: 0px 0px 10px #f4f4f4;
    padding: 9px 3px;
}
.totale-prodects {
    margin: 19px auto 18px !important;
}
.valid{
    border: 1px solid #f00;
}
.modal-footer .btn
{
    margin-top: 277px;
    margin-left: 62%;
}
.modal-footer,.modal-content{
    border: none;
}

button.btn.btn-primary.selectbtn {
    background: #fafafa;
    color: #000000b8;
    border-color: #ddd;
    text-align: start;
}
.css-13cymwt-control {
    background: inherit !important;
}