.cheked-data{
    background: #FAFAFA;
    padding:  5px;
    color: #2B2F38;
    padding: 5px 14px;
}
.SelectShipping .cheked .form-check-label ,.cheked-data .form-check-label{
margin-right: 35px;
}
.SelectShipping .cheked-data img {
    width: 35px;
}