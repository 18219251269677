.slider-prodect{
    text-align: center;
    background-image: url("../../../asset/images/Group\ 4025392.webp");
    background-size: cover;
    padding: 48px 0px;
    color: #fff;
    line-height: 2;
}

button.active.btn {
    background: #eee;
}
.slider-prodect .btn-bottom button:hover{
    background: #ddd !important;
    color: #1fbbe3 !important;


}
.slider-prodect .slick-prev:before,.slider-prodect .slick-next:before
{
   color: #fff; 

}
.slider-prodect img
{
    height: 250px;
    object-fit: contain;

}
.slider-prodects  .slick-track {
    height: 550px;
}
.slider-prodect .card-body
{
    line-height: 1;
}
.slider-prodect  .card {
   
    height: 500px;
}

.slider-prodect .old-price {
    text-decoration: line-through;
    color: #80848a;
}
span.new-price {
    color: #1FBBE3;
}
p.card-text {
    color: #80848A;
}

.icon-card
{
    color: #80848A;
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
}
.icon-card span,.icon-card button
{
    background: #eee ;
    display: flex;
    width: 47px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    height: 47px;
    margin: 20px auto;
    border: none;
    font-size: 20px;
}
.icon-card span.active{
    background: #1FBBE3;
    color: #fff;

}
.added{
    background: #1fbbe3 !important;
    color: #fff !important;
}
.reting-div
{
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 20px;
    color: #000;
    background: #fff;
    padding: 1px 5px;
    color: #6E6E6E;
}
.reting-div .icon{
    color:#FFC300
}
.sale-div
{
    position: absolute;
    color: #000;
    width: 200px;
    height: 200px;
    left: -43px;
    top: 17px;
}
.sale-div div{
    background: #5F4898;
    color: #fff;
    transform: rotate(318deg);
    text-align: center;
    padding: 8px;
    font-size: 20px;
}
.card{
    overflow: hidden;
    min-height: unset !important;
    
}
.slider-prodect  button.slick-arrow.slick-prev {
    position: absolute;
    top: 102% !important;
    left: 46%;
    z-index: 10;
}

        /* button.slick-arrow.slick-next */
.btn-bottom button
{
    background: #fff;
    color: #1fbbe3;
    font-size: 20px;
    margin-top: 30px;
    width: 184px;
    padding: 10px;
    border-radius: 0px;
    font-weight: 500;


    
}
.slider-prodect .slick-initialized .slick-slide,.slider-prodect .slick-slide.slick-active.slick-current {
    height: 100%;
    display:unset;
    padding: 0px;
    
}
.slider-prodect button.slick-arrow.slick-next {
    position: absolute;
    top: 102% !important;
    right: 46% !important;
    z-index: 10;
}
.no-underline
{
    text-decoration: unset;
}
@media screen and (max-width:768px) {
 .slider-prodect button.slick-arrow.slick-next {
    right: 38% !important;
}
.slider-prodect button.slick-arrow.slick-prev {
    left: 38% !important;
}
}
.back-light{
    background: #fff;
    background: #fff;
    box-shadow: 0px 0px 15px #dddd;
    font-size: 18px;
}