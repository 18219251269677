div#freeprodect {
    z-index: 100000000000000000;
}
.input-quntaty
{
    min-width: 56px;
    border: 1px solid #ddd;
    border-radius: 13px;
    margin: 4px;
    text-align: center;
}
